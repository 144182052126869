<template>
  <div class="imgbox">
    <div class="img1 img"></div>
    <div class="img2dw">
      <div class="img2 img"></div>
    </div>
    <div class="img3dw">
        <div class="img3 img"></div>
    </div>
    <!-- <img src="../assets/hh/hh-01.png" alt="" />
    <img src="../assets/hh/hh-02.png" alt="" />
    <img src="../assets/hh/hh-03.png" alt="" /> -->
  </div>
</template>
<style scoped>
.imgbox {
  min-height: 3000px;
}
.img{
      min-width: 1320px;
      background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

}
.img1 {
  position: relative;
  width: 100%;
  height: 1031px;
  background-image: url("../assets/hh/hh-01.png");
}
.img2dw {
  position: absolute;
  left: 0;
  top: 970px;
  width: 100%;
}
.img3dw {
  position: absolute;
  left: 0;
  top: 1900px;
  width: 100%;
}
.img2 {
  position: relative;
  width: 100%;
  height: 950px;
  background-image: url("../assets/hh/hh-02.png");

}
.img3 {
  position: relative;
  width: 100%;
  height: 989px;
  background-image: url("../assets/hh/hh-03.png");
}

</style>